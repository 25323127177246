import React, { useContext, useState } from 'react';
import { FormContext } from '../../context/FormContext';
import Input from "../CustomInput";
import { FaUserCircle } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { FaPhone } from "react-icons/fa6";

const Step6 = ({ nextStep, prevStep }) => {
  const { formData, updateFormData } = useContext(FormContext);
  const [contact, setContact] = useState(formData.administrationContact || {});
  const [errors, setErrors] = useState({});

   // aceita 13 dígitos como: +XX XX XXXXX XXXX
  const formatPhone = (value) => {
    let digits = value.replace(/\D/g, '').slice(0, 13);

    let formatted = '+';
    if (digits.length > 0) {
      const group1 = digits.slice(0, 2);  // Código do país (2 dígitos)
      const group2 = digits.slice(2, 4);  // DDD (2 dígitos)
      const group3 = digits.slice(4, 9);  // Primeira parte do número (5 dígitos)
      const group4 = digits.slice(9, 13); // Segunda parte do número (4 dígitos)

      formatted += group1;
      if (group2) formatted += ' ' + group2;
      if (group3) formatted += ' ' + group3;
      if (group4) formatted += ' ' + group4;
    }
    return formatted;
  };

  const handleChange = (field, value) => {
    setContact(prev => ({ ...prev, [field]: value }));
  };

  const handleMobilePhoneChange = (e) => {
    let inputValue = e.target.value;
    // Garante que o '+' esteja sempre no início
    if (!inputValue.startsWith('+')) {
      inputValue = '+' + inputValue;
    }
    const formatted = formatPhone(inputValue);
    handleChange('mobilePhone', formatted);
  };

  const handlePhoneFocus = () => {
    setErrors(prev => ({ ...prev, mobilePhone: '' }));
  };

  const handleNext = () => {
    // Validação dos campos obrigatórios
    if (!contact.firstName || !contact.email || !contact.lastName) {
      setErrors({
        firstName: !contact.firstName ? 'Primeiro Nome é obrigatório' : '',
        lastName: !contact.lastName ? 'Último Nome é obrigatório' : '',
        email: !contact.email ? 'Email é obrigatório' : '',
      });
      return;
    }

    // Validação do formato do email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/ || '';
    if (!emailRegex.test(contact.email)) {
      setErrors(prev => ({ ...prev, email: 'Formato de email inválido (_@_._)' }));
      return;
    }

     // Validação do formato do telemóvel: deve ser +55 XX XXXX XXXX
     if (contact.mobilePhone) {
      const phoneRegex = /^\+\d{2}\s\d{2}\s\d{5}\s\d{4}$/;
      if (!phoneRegex.test(contact.mobilePhone)) {
        setErrors(prev => ({ 
          ...prev, 
          mobilePhone: 'Formato de celular inválido (ex: +55 11 91234 1234)' 
        }));
        return;
      }
    }

    updateFormData({ administrationContact: contact });
    nextStep();
  };

  return (
    <div>
      <h2 className="text-xl text-white font-bold mb-4">6º Passo: Contacto Administrador</h2>
      <div className="mb-4">
        <Input
          type="text"
          value={contact?.firstName}
          onChange={(e) => handleChange('firstName', e.target.value)}
          label={"Primeiro Nome"}
          icon={<FaUserCircle className="size-4" />}
          error={errors && errors.firstName}
        />
      </div>
      <div className="mb-4">
        <Input
          type="text"
          value={contact?.lastName}
          onChange={(e) => handleChange('lastName', e.target.value)}
          label={"Último Nome"}
          icon={<FaUserCircle className="size-4" />}
          error={errors && errors.lastName}
        />
      </div>
      <div className="mb-4">
        <Input
          type="email"
          value={contact?.email}
          onChange={(e) => handleChange('email', e.target.value)}
          label={"Email"}
          error={errors && errors.email}
          icon={<IoMdMail className="size-4" />}
        />
      </div>
      <div className="mb-4">
        <Input
          type="tel"
          value={contact?.mobilePhone}
          onChange={handleMobilePhoneChange}
          onFocus={handlePhoneFocus}
          label={"Celular (Opcional)"}
          icon={<FaPhone className="size-4" />}
          error={errors && errors.mobilePhone}
          required={false}
        />
      </div>
      <div className="flex justify-between gap-2">
        <button
          onClick={prevStep}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 items-center gap-2 w-full flex justify-center"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
          Voltar
        </button>
        <button
          onClick={handleNext}
          className="w-full flex justify-center items-center gap-x-2 rounded-md bg-[#0d6efd] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Seguinte
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Step6;
