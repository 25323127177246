import React, { useContext, useState } from 'react';
import { FormContext } from '../../context/FormContext';
import Input from '../CustomInput';
import { IoTerminal } from "react-icons/io5";
import { FaBuilding } from "react-icons/fa";
import { FaFileCircleCheck } from "react-icons/fa6";
import { validateTaxId } from '../../api/api'; 

const Step2 = ({ nextStep, prevStep }) => {
  const { formData, updateFormData } = useContext(FormContext);
  const orderLines = formData.orderLines;
  
  const [errors, setErrors] = useState([]);
  const [validationLoading, setValidationLoading] = useState({});
  const [validationError, setValidationError] = useState({});

  // Regex para validar o formato do Tax ID (CNPJ)
  const taxIdRegex = /^\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}$/;

  // Função para formatar o Tax ID conforme: 00.000.000/0000-00
  const formatTaxId = (value) => {
    const digits = value.replace(/\D/g, '').slice(0, 14);
    let formatted = '';

    if (digits.length >= 1) {
      formatted += digits.slice(0, 2);
    }
    if (digits.length >= 2) {
      formatted += '.';
    }
    if (digits.length > 2) {
      formatted += digits.slice(2, 5);
    }
    if (digits.length >= 5) {
      formatted += '.';
    }
    if (digits.length > 5) {
      formatted += digits.slice(5, 8);
    }
    if (digits.length >= 8) {
      formatted += '/';
    }
    if (digits.length > 8) {
      formatted += digits.slice(8, 12);
    }
    if (digits.length >= 12) {
      formatted += '-';
    }
    if (digits.length > 12) {
      formatted += digits.slice(12, 14);
    }

    return formatted;
  };

  const handleChange = (index, field, value) => {
    const updatedOrderLines = orderLines.map((orderLine, i) => {
      if (i === index) {
        const updatedLine = { ...orderLine, [field]: value };
        if (field === 'taxId') {
          value = formatTaxId(value);
          updatedLine.taxId = value;
          updatedLine.isValidated = false; // Invalida validação ao editar
        }
        return updatedLine;
      }
      return orderLine;
    });
    updateFormData({ orderLines: updatedOrderLines });
    setErrors(prev => {
      const newErrors = [...prev];
      if (newErrors[index]) newErrors[index][field] = null;
      return newErrors;
    });
  };

  // Handler para tratar o backspace no campo Tax ID
  const handleKeyDownTaxId = (index, event) => {
    if (event.key === 'Backspace') {
      const separators = ['.', '/', '-'];
      const input = event.target;
      const pos = input.selectionStart;
      const value = orderLines[index].taxId;
      if (pos > 0 && separators.includes(value[pos - 1])) {
        event.preventDefault();
        const digits = value.replace(/\D/g, '');
        const newDigits = digits.slice(0, -1);
        const newFormatted = formatTaxId(newDigits);
        handleChange(index, 'taxId', newFormatted);
      }
    }
  };

  // Função para validar o Tax ID via API
  const handleValidateTaxId = async (index) => {
    const taxId = orderLines[index].taxId;
    if (!taxId) {
      setValidationError(prev => ({ ...prev, [index]: 'Por favor insira um CNPJ' }));
      return;
    }
    setValidationError(prev => ({ ...prev, [index]: '' }));
    setValidationLoading(prev => ({ ...prev, [index]: true }));
    try {
      let formattedTaxId = await validateTaxId(taxId);
      formattedTaxId = formatTaxId(formattedTaxId);
      const updatedOrderLines = orderLines.map((ol, i) =>
        i === index ? { ...ol, taxId: formattedTaxId, isValidated: true } : ol
      );
      updateFormData({ orderLines: updatedOrderLines });
      setErrors(prev => {
        const newErrors = [...prev];
        if (newErrors[index]) newErrors[index].taxId = null;
        return newErrors;
      });
    } catch (error) {
      setValidationError(prev => ({ ...prev, [index]: error.message }));
      const updatedOrderLines = orderLines.map((ol, i) =>
        i === index ? { ...ol, isValidated: false } : ol
      );
      updateFormData({ orderLines: updatedOrderLines });
    } finally {
      setValidationLoading(prev => ({ ...prev, [index]: false }));
    }
  };  

  const handleNext = () => {
    let isError = false;
    const newErrors = [];
    setValidationError({});
    orderLines.forEach((orderLine, index) => {
      let error = {};
      if (!orderLine.taxId) {
        error.taxId = 'CNPJ é obrigatório';
        isError = true;
      } else if (!taxIdRegex.test(orderLine.taxId)) {
        error.taxId = 'Formato do CNPJ inválido (00.000.000/0000-00)';
        isError = true;
      } else if (!orderLine.isValidated) {
        error.taxId = 'Por favor, valide o CNPJ';
        isError = true;
      }
      if (!orderLine.name) {
        error.name = 'Nome da Companhia é obrigatório';
        isError = true;
      }
      if (!orderLine.shareholderCount) {
        error.shareholderCount = 'Número de Acionistas é obrigatório';
        isError = true;
      }
      newErrors[index] = error;
    });
    setErrors(newErrors);
  
    if (!isError) {
      nextStep();
    }
  };

  return (
    <div>
      <h2 className="text-xl text-white font-bold mb-4">2º Passo: Detalhes da Companhia</h2>
      {orderLines.map((company, index) => {
        const isTaxIdValid = company.taxId ? taxIdRegex.test(company.taxId) : false;
        const taxIdError = validationError[index] || (errors[index] && errors[index].taxId);

        return (
          <div key={index} className="mb-6 p-4 border rounded">
            <h3 className="text-lg text-white font-semibold mb-2">Companhia {index + 1}</h3>
            <div className="mb-4">
              <div className="flex items-start gap-2">
                <div className="flex-1">
                  <Input 
                    label={"CNPJ"}
                    value={company.taxId}
                    onChange={(e) => handleChange(index, 'taxId', e.target.value)}
                    onKeyDown={(e) => handleKeyDownTaxId(index, e)}
                    placeholder={'00.000.000/0000-00'}
                    pattern={"\\d{2}\\.?\\d{3}\\.?\\d{3}/?\\d{4}-?\\d{2}"}
                    error={taxIdError} 
                    icon={<IoTerminal className="size-4" />}
                  />
                </div>
                <button
                  type="button"
                  className="mt-8 h-[37.6px] flex justify-center items-center gap-x-2 rounded-md bg-[#008000] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm 
                             hover:bg-[#0F4D0F] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#125c12]"
                  onClick={() => handleValidateTaxId(index)}
                  disabled={validationLoading[index] || !isTaxIdValid}
                >
                  {validationLoading[index] ? 'Validando...' : 'Validar'}
                </button>
              </div>
              {!taxIdError && company.taxId && company.isValidated && (
                <p className="mt-1 text-sm text-green-600">
                  CNPJ validado
                </p>
              )}
            </div>
            <div className="mb-4">
              <Input 
                label={"Nome da Companhia"}
                value={company.name}
                onChange={(e) => handleChange(index, 'name', e.target.value)}
                error={errors && errors[index]?.name}
                icon={<FaBuilding className="size-4" />}
              />
            </div>
            <div className="mb-4">
              <Input 
                label={"Número de Acionistas"}
                value={company.shareholderCount}
                onChange={(e) => handleChange(index, 'shareholderCount', e.target.value)}
                error={errors && errors[index]?.shareholderCount}
                icon={<FaFileCircleCheck className="size-4"/>}
                type='number'
                min='1'
              />
            </div>
          </div>
        );
      })}
      <div className="flex justify-between gap-2">
        <button
          onClick={prevStep}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 flex items-center gap-2 w-full justify-center"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
          Voltar
        </button>
        <button
          onClick={handleNext}
          className="w-full flex justify-center items-center gap-x-2 rounded-md bg-[#0d6efd] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Seguinte
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Step2;
