import React, { useContext, useState } from 'react';
import { FormContext } from '../../context/FormContext';
import { validateCoupon } from '../../api/api';
import { IoTerminal } from "react-icons/io5";
import Input from '../CustomInput';

const Step4 = ({ nextStep, prevStep }) => {
  const { formData, updateFormData } = useContext(FormContext);

  const [couponCode, setCouponCode] = useState(formData.couponCode || '');
  const [errors, setErrors] = useState({});
  
  const [couponResult, setCouponResult] = useState(null);
  const [couponValidated, setCouponValidated] = useState(false);

  const [validationLoading, setValidationLoading] = useState(false);

  const handleValidateCoupon = async () => {
    if (!couponCode) {
      return;
    }
    setValidationLoading(true);
    setErrors({});
    setCouponResult(null);
    setCouponValidated(false);
  
    try {
      const result = await validateCoupon(couponCode);
  
      if (typeof result === 'object' && result !== null) {
        setCouponResult(result);
        setCouponValidated(true);
        updateFormData({ 
          couponCode, 
          couponDetails: result, 
          couponCodeAdditionalInfo: result.additionalInfoTitle 
        });
      } else {
        let message = result;
        if (message === "Coupon code has expired!") {
          message = "O código do cupão expirou!";
        } else if (message === "Coupon not found!") {
          message = "Cupão não encontrado!";
        }
        setErrors({ couponCode: message });
      }
    } catch (error) {
      setErrors({ couponCode: error.message || "Cupão inválido" });
    } finally {
      setValidationLoading(false);
    }
  };

  const handleNext = () => {
    if (!couponCode) {
      updateFormData({ 
        couponCode: '',
        couponDetails: null,
        couponCodeAdditionalInfo: ''
      });
      nextStep();
    } else if (couponValidated) {
      nextStep();
    } else {
      setErrors({ couponCode: "Valide o cupão antes de prosseguir." });
    }
  };

  return (
    <div>
      <h2 className="text-xl text-white font-bold mb-4">4º Passo: Cupão (Opcional) </h2>

      <div className="mb-4">
        
        <div className="flex items-start gap-2">
          <div className="flex-1">
            <Input
              type="text"
              value={couponCode}
              onChange={(e) => {
                setCouponCode(e.target.value);
                setCouponResult(null);
                setCouponValidated(false);
                setErrors({});
              }}
              error={errors && errors.couponCode}
              icon={<IoTerminal className="size-4" />}
            />
          </div>

          <button
            type="button"
            onClick={handleValidateCoupon}
            disabled={!couponCode || validationLoading}
            className="mt-2 h-[37.6px] flex justify-center items-center gap-x-2 rounded-md bg-[#008000] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm
              hover:bg-[#0F4D0F] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#125c12]"
          >
            {validationLoading ? 'Validando...' : 'Validar'}
          </button>
        </div>

        {couponValidated && couponResult && (
          <div className="mt-2 text-sm text-green-600">
            {couponResult.discount !== undefined && (
              <p>Desconto: {couponResult.discount * 100}%</p>
            )}
            {couponResult.additionalInfoTitle && (
              <p>Informação adicional: {couponResult.additionalInfoTitle}</p>
            )}
            {couponResult.maxTrialPeriod && (
              <p>Período de teste: {couponResult.maxTrialPeriod} dias</p>
            )}
            {couponResult.maxshareholderCount && (
              <p>Nº máximo de acionistas: {couponResult.maxshareholderCount}</p>
            )}
          </div>
        )}
      </div>

      <div className="flex justify-between gap-2">
        <button
          onClick={prevStep}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 items-center gap-2 w-full flex justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 24 24"
            strokeWidth="1.5" stroke="currentColor"
            className="size-6"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
          Voltar
        </button>

        <button
          onClick={handleNext}
          className="
            w-full flex justify-center items-center gap-x-2 rounded-md bg-[#0d6efd] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm
            hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Seguinte
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 24 24"
            strokeWidth="1.5" stroke="currentColor"
            className="size-6"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Step4;
