import React from 'react';
import './Footer.css'; 

const Footer = () => {
    return (
        <footer className="site-footer">
            <div className="container flex flex-col justify-center items-center mx-auto">
                <p>&copy; {new Date().getFullYear()} Scriptum Digital. Todos os direitos reservados.</p>
                <div className="footer-links">
                    <a 
                        href="https://scriptumdigital.com.br/politica-de-privacidade/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                            Política de Privacidade
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
