import React, { useContext, useState, useEffect } from 'react';
import { FormContext } from '../../context/FormContext';
import { fetchBillingDetails } from '../../api/api';
import { RiBillLine } from "react-icons/ri";
import { TbBuildingSkyscraper } from "react-icons/tb";
import { FaRegAddressCard, FaCity } from "react-icons/fa";
import { BsGeoAltFill } from "react-icons/bs";
import { PiMailboxBold } from "react-icons/pi";
import { BiWorld } from "react-icons/bi";
import Input from '../CustomInput';

const Step5 = ({ nextStep, prevStep }) => {
  const { formData, updateFormData } = useContext(FormContext);
  const [billingDetails, setBillingDetails] = useState(
    formData.billingAddress || {
    legalName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
  });
  const [billingTaxId, setBillingTaxId] = useState(formData.billingTaxId || '');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const taxIdRegex = /^\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}$/;

  const formatTaxId = (value) => {
    const digits = value.replace(/\D/g, '').slice(0, 14);
    const segments = [
      { len: 2, sep: '.' },
      { len: 3, sep: '.' },
      { len: 3, sep: '/' },
      { len: 4, sep: '-' },
      { len: 2, sep: '' },
    ];
  
    let formatted = '';
    let index = 0;
    segments.forEach((segment, i) => {
      const segValue = digits.slice(index, index + segment.len);
      index += segment.len;
      formatted += segValue;
      if (segValue.length === segment.len && i < segments.length - 1) {
        formatted += segment.sep;
      }
    });
    return formatted;
  };
  

  const handleKeyDownTaxId = (e) => {
    if (e.key === 'Backspace') {
      const separators = ['.', '/', '-'];
      const pos = e.target.selectionStart;
      if (pos > 0 && separators.includes(billingTaxId[pos - 1])) {
        e.preventDefault();
        const digits = billingTaxId.replace(/\D/g, '');
        const newDigits = digits.slice(0, -1);
        const newFormatted = formatTaxId(newDigits);
        setBillingTaxId(newFormatted);
      }
    }
  };

  const handleTaxIdChange = (e) => {
    const formatted = formatTaxId(e.target.value);
    setBillingTaxId(formatted);
  };

  // Formata o CEP: 00000-000
  const formatCEP = (value) => {
    const digits = value.replace(/\D/g, '').slice(0, 8);
    if (digits.length >= 5) {
      return digits.slice(0, 5) + '-' + digits.slice(5);
    }
    return digits;
  };

  const handleCEPChange = (value) => {
    const formatted = formatCEP(value);
    setBillingDetails(prev => ({ ...prev, postalCode: formatted }));
  };

  useEffect(() => {
    if (billingDetails.postalCode && !billingDetails.postalCode.includes('-')) {
      const formatted = formatCEP(billingDetails.postalCode);
      if (billingDetails.postalCode !== formatted) {
        setBillingDetails(prev => ({ ...prev, postalCode: formatted }));
      }
    }
  }, [billingDetails.postalCode]);
  

  const handleKeyDownCEP = (e) => {
    if (e.key === 'Backspace') {
      const separators = ['-'];
      const pos = e.target.selectionStart;
      const currentValue = billingDetails.postalCode || '';
      if (pos > 0 && separators.includes(currentValue[pos - 1])) {
        e.preventDefault();
        const digits = currentValue.replace(/\D/g, '');
        const newDigits = digits.slice(0, -1);
        const newFormatted = formatCEP(newDigits);
        setBillingDetails(prev => ({ ...prev, postalCode: newFormatted }));
      }
    }
  };

  const handleFetchDetails = async () => {
    if (!billingTaxId) {
      setErrors(prev => ({ ...prev, billingTaxId: 'Por favor insira um CNPJ' }));
      return;
    }
    if (!taxIdRegex.test(billingTaxId)) {
      setErrors(prev => ({ ...prev, billingTaxId: 'CNPJ inválido (00.000.000/0000-00)' }));
      return;
    }
    setErrors(prev => ({ ...prev, billingTaxId: '' }));
    setLoading(true);
    try {
      const details = await fetchBillingDetails(billingTaxId);
      if (details.error) {
        setErrors(prev => ({ ...prev, billingTaxId: 'Erro ao obter detalhes do CNPJ' }));
      } else {
        const mappedDetails = {
          legalName: details.company?.name || '',
          addressLine1: (details.address?.street || '') + ', ' + (details.address?.number || ''),
          addressLine2: details.address?.district || '',
          city: details.address?.city || '',
          state: details.address?.state || '',
          postalCode: formatCEP(details.address?.zip || ''),
          country: (details.address?.country && details.address.country.name) || 'Brasil',
        };
        setBillingDetails(mappedDetails);
      }
    } catch (error) {
      setErrors(prev => ({ ...prev, billingTaxId: error.message }));
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (field, value) => {
    setBillingDetails(prev => ({ ...prev, [field]: value }));
  };

  const handleTaxIdFocus = () => {
    setErrors(prev => ({ ...prev, billingTaxId: '' }));
  };

  useEffect(() => {
    if (
      JSON.stringify(formData.billingAddress) !== JSON.stringify(billingDetails) ||
      formData.billingTaxId !== billingTaxId
    ) {
      updateFormData({ billingAddress: billingDetails, billingTaxId });
    }
  }, [billingDetails, billingTaxId, formData.billingAddress, formData.billingTaxId, updateFormData]);

  const handleNext = () => {
    if (
      billingDetails.legalName && 
      billingDetails.addressLine1 && 
      billingDetails.addressLine2 && 
      billingDetails.city && 
      billingDetails.state && 
      billingDetails.postalCode && 
      billingTaxId
    ) {
      nextStep();
    } else {
      setErrors({
        legalName: !billingDetails.legalName ? 'Denominação legal da empresa é obrigatória' : '',
        addressLine1: !billingDetails.addressLine1 ? 'Endereço 1 é obrigatório' : '',
        addressLine2: !billingDetails.addressLine2 ? 'Endereço 2 é obrigatório' : '',
        city: !billingDetails.city ? 'Cidade é obrigatória' : '',
        state: !billingDetails.state ? 'Estado é obrigatório' : '',
        postalCode: !billingDetails.postalCode ? 'CEP é obrigatório' : '',
        billingTaxId: !billingTaxId ? 'CNPJ é obrigatório' : '',
      });
    } 
  };

  return (
    <div>
      <h2 className="text-xl text-white font-bold mb-4">5º Passo: Detalhes de cobrança</h2>
      <div className="mb-4">
        <div className="flex items-start gap-2">
          <div className="flex-1">
            <Input
              type="text"
              value={billingTaxId}
              onChange={handleTaxIdChange}
              onKeyDown={handleKeyDownTaxId}
              onFocus={handleTaxIdFocus}
              pattern="\d{2}\.?\d{3}\.?\d{3}/?\d{4}-?\d{2}"
              placeholder="00.000.000/0000-00"
              label="Faturamento (CNPJ)"
              error={errors?.billingTaxId}
              icon={<RiBillLine className="size-4" />}
            />
          </div>
          <button
            onClick={handleFetchDetails}
            className="mt-8 h-[37.6px] flex justify-center items-center gap-x-2 rounded-md bg-[#008000] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#0F4D0F] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#125c12]"
            disabled={loading}
          >
            {loading ? 'Procurando...' : 'Preencher'}
          </button>
        </div>
      </div>
      <div className="mb-4">
        <Input
          type="text"
          value={billingDetails?.legalName}
          onChange={(e) => handleChange('legalName', e.target.value)}
          label="Denominação legal da empresa"
          error={errors?.legalName}
          icon={<TbBuildingSkyscraper className="size-4" />}
        />
      </div>
      <div className="mb-4">
        <Input
          type="text"
          value={billingDetails?.addressLine1}
          onChange={(e) => handleChange('addressLine1', e.target.value)}
          label="Linha de endereço 1 (Logradouro)"
          error={errors?.addressLine1}
          icon={<FaRegAddressCard className="size-4" />}
        />
      </div>
      <div className="mb-4">
        <Input
          type="text"
          value={billingDetails?.addressLine2}
          onChange={(e) => handleChange('addressLine2', e.target.value)}
          label="Linha de endereço 2 (Bairro)"
          error={errors?.addressLine2}
          icon={<FaRegAddressCard className="size-4" />}
        />
      </div>
      <div className="mb-4">
        <Input
          type="text"
          value={billingDetails?.city}
          onChange={(e) => handleChange('city', e.target.value)}
          label="Cidade"
          error={errors?.city}
          icon={<FaCity className="size-4" />}
        />
      </div>
      <div className="mb-4">
        <Input
          type="text"
          value={billingDetails?.state}
          onChange={(e) => handleChange('state', e.target.value)}
          label="Estado"
          error={errors?.state}
          icon={<BsGeoAltFill className="size-4" />}
        />
      </div>
      <div className="mb-4 flex items-end">
        <div className="flex-grow">
          <Input
            type="text"
            value={billingDetails?.postalCode}
            onChange={(e) => handleCEPChange(e.target.value)}
            onKeyDown={handleKeyDownCEP}
            placeholder="00000-000"
            label="Código Postal (CEP)"
            error={errors?.postalCode}
            icon={<PiMailboxBold className="size-4" />}
          />
        </div>
      </div>
      <div className="mb-4">
        <Input
          type="text"
          value={billingDetails?.country}
          onChange={(e) => handleChange('country', e.target.value)}
          label="País"
          error={errors?.country}
          icon={<BiWorld className="size-4" />}
        />
      </div>
      <div className="flex justify-between gap-2">
        <button
          onClick={prevStep}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 items-center gap-2 w-full flex justify-center"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
          Voltar
        </button>
        <button
          onClick={handleNext}
          className="w-full flex justify-center items-center gap-x-2 rounded-md bg-[#0d6efd] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Seguinte
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Step5;
