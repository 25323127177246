import React, { useContext, useState } from 'react';
import { FormContext } from '../../context/FormContext';
import { submitOrder } from '../../api/api';
import { BsFillBuildingsFill } from "react-icons/bs";
import { FaClock, FaMoneyBillWave, FaTicketAlt, FaFileInvoiceDollar, FaUserCircle, FaChartLine, FaRegCheckCircle } from "react-icons/fa";

const Step11 = ({ prevStep }) => {
  const { formData } = useContext(FormContext);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [orderNumber, setOrderNumber] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await submitOrder(formData);
      console.log(response);
      if (response && (typeof response === 'string' || response.success)) {
        if (typeof response === 'string') {
          setOrderNumber(response);
        } else {
          setOrderNumber(response.orderNumber || '');
        }
        setSuccess(true);
      } else if (response && response.errors) {
        // Se houver o objeto de erros, salvamos ele no state
        setError(response.errors);
      } else {
        setError(response.message || 'Ocorreu um erro ao processar o pedido.');
      }
    } catch (err) {
      setError(err.message || 'Erro inesperado ao enviar pedido.');
    } finally {
      setLoading(false);
    }
  };

  if (success) {
    return (
      <div className="text-center text-white">
        <h2 className="flex items-center gap-2 justify-center text-2xl font-bold mb-4"><FaRegCheckCircle /> Pedido enviado com sucesso!</h2>
        {orderNumber && (
          <p>
            O número da sua encomenda é <strong>{orderNumber}</strong>
          </p>
        )}
        <p>Obrigado pelo seu pedido.</p>
      </div>
    );
  }

  return (
    <div className="p-6 shadow text-white">
      <h2 className="text-2xl font-bold mb-4 text-center">Resumo de Encomenda</h2>

      {/* GRID para dados gerais */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div>
          <div className="flex items-center gap-2">
            <BsFillBuildingsFill className="size-4" />
            <h3 className="font-semibold">Número de Companhias:</h3>
          </div>
          <p>{formData.numberOforderLines}</p>
        </div>
        <div>
          <div className="flex items-center gap-2">
            <FaClock className="size-4" />
            <h3 className="font-semibold">Frequência de cobrança:</h3>
          </div>
          <p>{formData.billingFrequency}</p>
        </div>
        <div>
          <div className="flex items-center gap-2">
            <FaMoneyBillWave className="size-4" />
            <h3 className="font-semibold">Método de Pagamento:</h3>
          </div>
          <p>
            {formData.paymentMethod === 'Bank Transfer'
              ? 'Transferência Bancária'
              : 'Boleto Bancário'}
          </p>

        </div>
        <div>
          <div className="flex items-center gap-2">
            <FaTicketAlt className="size-4" />
            <h3 className="font-semibold">Cupão:</h3>
          </div>
          <p>{formData?.couponCode ? formData.couponCode : "Sem código"}</p>
        </div>
      </div>

      {/* Tabela para as companhias */}
      <div className="mt-6">
        <div className="flex items-center gap-2 mb-2">
          {/* <BsFillBuildingsFill className="size-4">*/}
          <h3 className="font-semibold text-lg">Companhias:</h3>
        </div>
        <table className="w-full text-white text-sm">
          <thead>
            <tr className="border-b border-white/20">
              <th className="text-left py-2">CNPJ</th>
              <th className="text-left py-2">Nome</th>
              <th className="text-left py-2">Acionistas</th>
            </tr>
          </thead>
          <tbody>
            {formData.orderLines.map((company, index) => (
              <tr key={index} className="border-b border-white/20">
                <td className="py-2">{company?.taxId}</td>
                <td className="py-2">{company?.name}</td>
                <td className="py-2">{company?.shareholderCount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Endereços e cobrança */}
      <div className="mt-6">
        <div className="flex items-center gap-2 mb-3">
          <FaFileInvoiceDollar className="size-4" />
          <h3 className="font-semibold text-lg">Detalhes de cobrança:</h3>
        </div>
        <p className="mb-1">
          <span className="font-semibold">CNPJ:</span> {formData.billingTaxId}
        </p>
        {formData.billingAddress.legalName && (
          <p className="mb-1">
            <span className="font-semibold">Nome Legal:</span> {formData.billingAddress.legalName}
          </p>
        )}
        <p>
          <span className="font-semibold">Endereço:</span>{' '}
          {formData.billingAddress.addressLine1}, {formData.billingAddress.addressLine2},{' '}
          {formData.billingAddress.city}, {formData.billingAddress.state},{' '}
          {formData.billingAddress.postalCode}, {formData.billingAddress.country}
        </p>
      </div>

      {/* Contatos */}
      <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-6">
        <div>
          <div className="flex items-center gap-2 mb-2">
            <FaUserCircle className="size-4" />
            <h3 className="font-semibold text-base">Contato de Administração:</h3>
          </div>
          <p>
            <span className="font-semibold">Nome:</span> {formData.administrationContact.firstName}{' '}
            {formData.administrationContact.lastName}
          </p>
          <p>
            <span className="font-semibold">Email:</span> {formData.administrationContact.email}
          </p>
          <p>
            <span className="font-semibold">Celular:</span>{' '}
            {formData?.administrationContact?.mobilePhone
              ? formData.administrationContact.mobilePhone
              : "Sem celular"}
          </p>
        </div>
        <div>
          <div className="flex items-center gap-2 mb-2">
            <FaUserCircle className="size-4" />
            <h3 className="font-semibold text-base">Contato de Cobrança:</h3>
          </div>
            <>
              <p>
                <span className="font-semibold">Nome:</span> {formData.billingContact.firstName}{' '}
                {formData.billingContact.lastName}
              </p>
              <p>
                <span className="font-semibold">Email:</span> {formData.billingContact.email}
              </p>
              <p>
                <span className="font-semibold">Celular:</span>{' '}
                {formData?.billingContact?.businessPhone
                  ? formData.billingContact.businessPhone
                  : "Sem celular"}
              </p>
            </>
        
        </div>
      </div>

      {/* Fonte da venda */}
      <div className="mt-6">
        <div className="flex items-center gap-2 mb-2">
          <FaChartLine className="size-4" />
          <h3 className="font-semibold text-lg">Fonte da venda:</h3>
        </div>
        <p>{formData.saleSource ? formData.saleSource : "Sem fonte de venda selecionada"}</p>
      </div>

      {/* Botões */}
      <div className="mt-6 flex justify-between gap-2">
        <button
          onClick={prevStep}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 w-full flex justify-center"
        >
          Voltar
        </button>
        <button
          onClick={handleSubmit}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 w-full"
          disabled={loading}
        >
          {loading ? 'Enviando...' : 'Enviar'}
        </button>
      </div>
        
      <div className="mt-6">
        {error && (
          <div className="bg-red-500 text-white p-3 mb-4 rounded text-center">
            <strong>O pedido não foi processado</strong>
            {typeof error === 'object' ? (
              <ul className="mt-2 list-disc list-inside">
                {Object.entries(error).map(([field, messages]) =>
                  messages.map((msg, index) => (
                    <li key={`${field}-${index}`}>{msg}</li>
                  ))
                )}
              </ul>
            ) : (
              <span className="ml-2">{error}</span>
            )}
          </div>
        )}
      </div>

    </div>
    
  );
};

export default Step11;
