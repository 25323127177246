import React, { useContext, useState } from 'react';
import { FormContext } from '../../context/FormContext';

const Step10 = ({ nextStep, prevStep }) => {
  const { formData, updateFormData } = useContext(FormContext);
  const [saleSource, setSaleSource] = useState(formData.saleSource || '');

  const handleChange = (e) => {
    const value = e.target.value;
    setSaleSource(value);
    updateFormData({ saleSource: value });
  };

  const handleNext = () => {
    updateFormData({ saleSource: saleSource || '' });
    nextStep();
  };

  return (
    <div>
      <h2 className="text-xl text-white font-bold mb-4">10º Passo: Fonte de Venda (Opcional)</h2>
      <div className="mb-4">
        <label className="block mb-2 text-white">Onde nos encontrou?</label>
        <select
          value={saleSource}
          onChange={handleChange}
          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none"
        >
          <option value="">Selecione uma opção</option>
          <option value="Pesquisa orgânica">Pesquisa orgânica</option>
          <option value="Pesquisa paga">Pesquisa paga</option>
          <option value="Protocolo de Parceiro">Protocolo de Parceiro</option>
          <option value="Conteúdo de Parceiros">Conteúdo de Parceiros</option>
          <option value="Email Marketing">Email Marketing</option>
          <option value="Social Orgânico">Social Orgânico</option>
          <option value="Social pago">Social pago</option>
          <option value="Indicação de clientes">Indicação de clientes</option>
          <option value="Fontes off-line">Fontes off-line</option>
        </select>
      </div>
      <div className="flex justify-between gap-2">
        <button
          onClick={prevStep}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 items-center gap-2 w-full flex justify-center"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
          Voltar
        </button>
        <button
          onClick={handleNext}
          className="w-full flex justify-center items-center gap-x-2 rounded-md bg-[#0d6efd] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Seguinte
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Step10;
