import './App.css';
import { FormProvider } from './context/FormContext';
import MultiStepForm from './MultiStepForm';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <FormProvider>
        <MultiStepForm />
        <Footer/>
      </FormProvider>
    </div>
  );
}

export default App;
