import React, { useContext, useState } from 'react';
import { FormContext } from '../../context/FormContext';
import { FaUserCircle } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { FaPhone } from "react-icons/fa6";
import Input from "../CustomInput";

const Step7 = ({ nextStep, prevStep }) => {
  const { formData, updateFormData } = useContext(FormContext);
  const [billingContact, setBillingContact] = useState(
    formData.billingContact || {
      sameAsAdmin: false,
      firstName: '',
      lastName: '',
      email: '',
      businessPhone: ''
    }
  );
  const [errors, setErrors] = useState({});

  const handleChange = (field, value) => {
    setBillingContact(prev => ({ ...prev, [field]: value }));
  };

  
  const handleCheckbox = () => {
    if (!billingContact.sameAsAdmin) {
      setBillingContact({
        sameAsAdmin: true,
        firstName: formData.administrationContact?.firstName || '',
        lastName: formData.administrationContact?.lastName || '',
        email: formData.administrationContact?.email || '',
        businessPhone: formData.administrationContact?.mobilePhone || '',
      });
    } else {
      setBillingContact({
        sameAsAdmin: false,
        firstName: '',
        lastName: '',
        email: '',
        businessPhone: '',
      });
    }
  };

  // Função para formatar o telefone – você pode adaptar se necessário
  const formatPhone = (value) => {
    // Remove todos os caracteres que não sejam dígitos e limita a 13 dígitos
    let digits = value.replace(/\D/g, '').slice(0, 13);
  
    let formatted = '+';
    if (digits.length > 0) {
      const group1 = digits.slice(0, 2);  // Código do país (2 dígitos)
      const group2 = digits.slice(2, 4);  // DDD (2 dígitos)
      const group3 = digits.slice(4, 9);  // Primeira parte do número (5 dígitos)
      const group4 = digits.slice(9, 13); // Segunda parte do número (4 dígitos)
  
      formatted += group1;
      if (group2) formatted += ' ' + group2;
      if (group3) formatted += ' ' + group3;
      if (group4) formatted += ' ' + group4;
    }
    return formatted;
  };

  const handleBusinessPhoneChange = (e) => {
    let inputValue = e.target.value;
    if (!inputValue.startsWith('+')) {
      inputValue = '+' + inputValue;
    }
    const formatted = formatPhone(inputValue);
    handleChange('businessPhone', formatted);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value.trim().toLowerCase();
    handleChange('email', value);
  };

  const handlePhoneFocus = () => {
    setErrors(prev => ({ ...prev, mobilePhone: '' }));
  };

  const handleNext = () => {
    // Validação dos campos obrigatórios
    if (!billingContact.firstName || !billingContact.lastName || !billingContact.email) {
      setErrors({
        firstName: !billingContact.firstName ? 'Primeiro Nome é obrigatório' : '',
        lastName: !billingContact.lastName ? 'Último Nome é obrigatório' : '',
        email: !billingContact.email ? 'Email é obrigatório' : '',
      });
      return;
    }

    // Validação do formato do email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(billingContact.email)) {
      setErrors(prev => ({ ...prev, email: 'Formato de email inválido (_@_._)' }));
      return;
    }

    // Se houver telefone, valida o formato (ex: (11) 91234-5678)
    if (billingContact.businessPhone) {
      const phoneRegex = /^\+\d{2}\s\d{2}\s\d{5}\s\d{4}$/;
      if (!phoneRegex.test(billingContact.businessPhone)) {
        setErrors(prev => ({ 
          ...prev, 
          businessPhone: 'Formato de celular inválido (ex: +55 11 91234 1234)' 
        }));
        return;
      }
    }

    updateFormData({ billingContact });
    nextStep();
  };

  return (
    <div>
      <h2 className="text-xl text-white font-bold mb-4">7º Passo: Contacto de Cobrança</h2>
      <div className="mb-4">
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            checked={billingContact.sameAsAdmin}
            onChange={handleCheckbox}
            className="form-checkbox"
          />
          <span className="ml-2 text-white">O mesmo que o contacto administrativo</span>
        </label>
      </div>
      {/* Os campos são sempre renderizados */}
      <div className="mb-4">
        <Input
          type="text"
          value={billingContact.firstName}
          onChange={(e) => handleChange('firstName', e.target.value)}
          label="Primeiro Nome"
          error={errors.firstName}
          icon={<FaUserCircle className="size-4" />}
        />
      </div>
      <div className="mb-4">
        <Input
          type="text"
          value={billingContact.lastName}
          onChange={(e) => handleChange('lastName', e.target.value)}
          onFocus={handlePhoneFocus}
          label="Último Nome"
          error={errors.lastName}
          icon={<FaUserCircle className="size-4" />}
        />
      </div>
      <div className="mb-4">
        <Input
          type="email"
          value={billingContact.email}
          onChange={handleEmailChange}
          label="Email"
          error={errors.email}
          icon={<IoMdMail className="size-4" />}
        />
      </div>
      <div className="mb-4">
        <Input
          type="tel"
          value={billingContact.businessPhone}
          onChange={handleBusinessPhoneChange}
          label="Celular (opcional)"
          error={errors.businessPhone}
          icon={<FaPhone className="size-4" />}
          required={false}
        />
      </div>
      <div className="flex justify-between gap-2">
        <button
          onClick={prevStep}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 flex items-center gap-2 w-full justify-center"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
          Voltar
        </button>
        <button
          onClick={handleNext}
          className="w-full flex justify-center items-center gap-x-2 rounded-md bg-[#0d6efd] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Seguinte
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Step7;
