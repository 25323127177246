import React, { useContext, useEffect, useState } from 'react';
import { FormContext } from '../../context/FormContext';
import { calculateTotalAmount } from '../../api/api';

const Step3 = ({ nextStep, prevStep }) => {
  const { formData, updateFormData } = useContext(FormContext);
  const [total, setTotal] = useState({ mensal: 0, anual: 0 });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTotal = async () => {
      if (formData.billingFrequency) {
        setLoading(true);
        const amount = await calculateTotalAmount(formData);
        setTotal(amount);
        setLoading(false);
      }
    };
    fetchTotal();
  }, [formData]);

  const handleChange = (e) => {
    updateFormData({ billingFrequency: e.target.value });
  };

  const handleNext = () => {
    nextStep();
  };

  return (
    <div>
      <h2 className="text-xl text-white font-bold mb-4">3º Passo: Resumo da Encomenda</h2>
      
      <div className="mb-4">
        <label className="block mb-2 text-white">Frequência de cobrança:</label>
        <div className="flex flex-col">
          <div className="flex items-center mb-2 ml-2">
            <input
              type="radio"
              id="mensal"
              name="billingFrequency"
              value="Mensal"
              checked={formData.billingFrequency === 'Mensal'}
              onChange={handleChange}
              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
            />
            <label htmlFor="mensal" className="ml-2 text-white">
              Mensal
            </label>
          </div>
          <div className="flex items-center ml-2">
            <input
              type="radio"
              id="anual"
              name="billingFrequency"
              value="Anual"
              checked={formData.billingFrequency === 'Anual'}
              onChange={handleChange}
              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
            />
            <label htmlFor="anual" className="ml-2 text-white">
              Anual
            </label>
          </div>
        </div>
      </div>

      <div className="mb-4 text-white">
        {loading ? (
          <p>Calculando ...</p>
        ) : formData.billingFrequency === "Mensal" ? (
          <p>Valor Mensal: R$ {total?.totalAmmount?.toFixed(2)}</p>
        ) : formData.billingFrequency === "Anual" ? (
          <p>Valor Anual: R$ {total?.totalAmmount?.toFixed(2)}</p>
        ) : null}
      </div>

      <div className="flex justify-between gap-2">
        <button
          onClick={prevStep}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 flex items-center gap-2 w-full justify-center"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
          Voltar
        </button>
        <button
          onClick={handleNext}
          className="w-full flex justify-center items-center gap-x-2 rounded-md bg-[#0d6efd] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Seguinte
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Step3;