import React, { createContext, useState, useCallback } from 'react';

export const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    "billingFrequency": "Mensal",
    "trialPeriod": 0,
    "paymentMethod": "",
    "couponCode": "",
    "billingTaxId": "",
    "billingAddress": {
      "legalName": "",  
      "addressLine1": "",
      "addressLine2": "",
      "city": "",
      "state": "",
      "postalCode": "",
      "country": ""
    },
    "administrationContact": {
      "firstName": "",
      "lastName": "",
      "email": "",
      "mobilePhone": ""
    },
    "billingContact": {
      "sameAsAdmin": false, 
      "firstName": "",
      "lastName": "",
      "email": "",
      "businessPhone": ""
    },
    "saleSource": "Não selecionada",
    "saleSourceCampaign": "",
    "notes": "",
    orderLines: [
      {
        taxId: '',
        name: '',
        shareholderCount: 0
      }
    ], 
    "numberOforderLines": "1", 
  });

  const updateFormData = useCallback((newData) => {
    setFormData(prev => ({ ...prev, ...newData }));
  }, []);

  return (
    <FormContext.Provider value={{ formData, updateFormData }}>
      {children}
    </FormContext.Provider>
  );
};

export default FormContext;
