import axios from "../setupAxios";

// Mock API functions

export const calculateTotalAmount = async (formData) => {


    const shareholdersCount = formData?.orderLines?.map(c => {
        return `shareholderCount=${c.shareholderCount}`
    });
    try {
        let frequency = "monthly"
        if (formData?.billingFrequency === "Anual") {
            frequency = "annually"
        } 


        const { data } = await axios.get(`/api/v1/order/OrderResume?${shareholdersCount.join("&")}&billingFrequency=${frequency}&couponCode=${formData?.couponCode}`, 
            {
                withCredentials: false, 
                headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            }
        );
        return data;
    } catch (error) {
        
    }

    // Simulate API delay
    await new Promise(resolve => setTimeout(resolve, 500));
  
    // Example calculation
    const baseAmount = formData.numberOforderLines * 100; // R$100 per company
    const monthly = baseAmount;
    const annually = baseAmount * 10; // 2 months free
  
    return { monthly, annually };
};


export const validateTaxId = async (taxId) => {
    if (!taxId) {
      throw new Error('Tax ID is required');
    }
    const encodedTaxId = encodeURIComponent(taxId);
    try {
      const { data } = await axios.get(`/api/v1/order/ValidateTaxId/${encodedTaxId}`);
      return data; // Supondo que o endpoint retorne o Tax ID formatado
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          throw new Error('Tax ID is null or invalid.');
        } else if (error.response.status === 409) {
          throw new Error('Tax ID is already being used.');
        }
      }
      throw new Error('Failed to validate Tax ID.');
    }
  };
  
export const validateCoupon = async (code) => {

    try {
        const { data } = await axios.get(`/api/v1/order/ValidateCoupon/${code}`);
        return data;
    } catch (error) {
        return error;
    }
};
  
export const fetchBillingDetails = async (taxId) => {
  const sanitizedTaxId = taxId.replace(/\D/g, '').slice(0, 14);

  try {
    const response = await axios.get(`https://open.cnpja.com/office/${sanitizedTaxId}`);
    // 49.804.330/0001-29
    if (response.status !== 200) {
      throw new Error('Erro ao obter detalhes do CNPJ');
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};

  
export const submitOrder = async (formData) => {
    try {
        const body = transformFormData(formData);
        const { data } = await axios.post(`/api/v1/order/`, body);

        return data;
    } catch (error) {
        console.log(error)
        return error;       
    }
};  

function transformFormData(formData) {
  const cleanPaymentMethod = formData.paymentMethod.replace(/\s+/g, "");
  const payload = {
    billingFrequency: formData.billingFrequency === "Mensal" ? "Monthly" : "Annually",
    trialPeriod: formData.trialPeriod,
    paymentMethod: cleanPaymentMethod,
    couponCode: formData.couponCode,
    billingTaxId: formData.billingTaxId,
    billingAddress: {
      addressLine1: formData.billingAddress.addressLine1,
      addressLine2: formData.billingAddress.addressLine2,
      city: formData.billingAddress.city,
      state: formData.billingAddress.state,
      postalCode: formatPostalCode(formData.billingAddress.postalCode),
      country: formData.billingAddress.country,
    },
    administrationContact: {
      firstName: formData.administrationContact.firstName,
      lastName: formData.administrationContact.lastName,
      email: formData.administrationContact.email,
      mobilePhone: formData.administrationContact.mobilePhone,
    },
    billingContact: {
      firstName: formData.billingContact.firstName,
      lastName: formData.billingContact.lastName,
      email: formData.billingContact.email,
      businessPhone: formData.billingContact.businessPhone,
    },
    saleSource: getSaleSourceValue(formData.saleSource),
    saleSourceCampaign: formData.saleSourceCampaign,
    notes: formData.notes,
    orderLines: formData.orderLines.map(line => ({
      taxId: line.taxId,
      name: line.name,
      shareholderCount: Number(line.shareholderCount),
    }))
  };
  if (formData.couponCodeAdditionalInfo) {
    payload.couponCodeAdditionalInfo = formData.couponCodeAdditionalInfo;
  }

  return payload;
}

const getSaleSourceValue = (key) => {
  const saleSourceMap = {
    "Pesquisa orgânica": "OrganicSearch",
    "Pesquisa paga": "PaidSearch",
    "Protocolo de Parceiro": "PartnerProtocol",
    "Conteúdo de Parceiros": "PartnerContent",
    "Email Marketing": "EmailMarketing",
    "Social Orgânico": "OrganicSocial",
    "Social pago": "PaidSocial",
    "Indicação de clientes": "CustomerReferral",
    "Fontes off-line": "OfflineSources",
  };

  return saleSourceMap[key] || "OrganicSearch";
};

function formatPostalCode(postalCode) {
  if (postalCode.length === 8) {
    return postalCode.slice(0, 5) + '-' + postalCode.slice(5);
  }
  return postalCode;
}
