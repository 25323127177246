import React, { useContext, useState } from 'react';
import { FormContext } from '../../context/FormContext';
import Input from '../CustomInput';
import { BsFillBuildingsFill } from "react-icons/bs";

const Step1 = ({ nextStep, updateValidity }) => {
    const { formData, updateFormData } = useContext(FormContext);
    const [number, setNumber] = useState(formData.numberOforderLines || "");  
    const [ errors, setErrors ] = useState({});

    const handleNext = () => {
      const numericValue = Number(number);
      if (!number || numericValue < 1) {
        setErrors({ number: "Introduza um número válido por favor (mínimo 1)." });
        return;
      }
      updateFormData({ numberOforderLines: numericValue });
      if (!formData.orderLines || formData.orderLines.length !== numericValue) {
        const orderLines = Array.from({ length: numericValue }, () => ({
          taxId: '',
          name: '',
          shareholderCount: '',
        }));
        updateFormData({ orderLines });
      }
      
      nextStep();
    };

  return (
    <div>
      <h2 className="text-xl text-white font-bold mb-4">1º Passo: Número de Companhias</h2>
      <div className="mb-4">
        <Input
          label={"Quantas companhias gostaria de adicionar na sua encomenda?"}
          type="number"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
          min="1"
          error={errors && errors?.number}
          icon={
            <BsFillBuildingsFill className='size-4'/>
          }
        />
      </div>
      {number >= 2 && (
        <div className="bg-gray-100 p-3 rounded mb-4">
          Todas as {number} companhias vão ser incluídas no mesmo recibo. Se necessita de recibos separados para cada encomenda, realize encomendas diferentes, obrigado.
        </div>
      )}
      <button
        onClick={handleNext}
        className="w-full flex justify-center items-center gap-x-2 rounded-md bg-[#0d6efd] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Seguinte
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>
      </button>
    </div>
  );
};

export default Step1;